exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-o-nas-index-js": () => import("./../../../src/pages/o-nas/index.js" /* webpackChunkName: "component---src-pages-o-nas-index-js" */),
  "component---src-pages-polityka-prywatnosci-index-js": () => import("./../../../src/pages/polityka-prywatnosci/index.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-index-js" */),
  "component---src-pages-tags-index-js": () => import("./../../../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-pages-thanks-index-js": () => import("./../../../src/pages/thanks/index.js" /* webpackChunkName: "component---src-pages-thanks-index-js" */),
  "component---src-templates-blog-blog-template-js": () => import("./../../../src/templates/Blog/blog-template.js" /* webpackChunkName: "component---src-templates-blog-blog-template-js" */),
  "component---src-templates-it-services-index-js": () => import("./../../../src/templates/ITServices/index.js" /* webpackChunkName: "component---src-templates-it-services-index-js" */),
  "component---src-templates-tags-tags-js": () => import("./../../../src/templates/Tags/tags.js" /* webpackChunkName: "component---src-templates-tags-tags-js" */),
  "component---src-templates-website-index-js": () => import("./../../../src/templates/Website/index.js" /* webpackChunkName: "component---src-templates-website-index-js" */),
  "component---src-templates-website-project-project-template-js": () => import("./../../../src/templates/WebsiteProject/project-template.js" /* webpackChunkName: "component---src-templates-website-project-project-template-js" */)
}

